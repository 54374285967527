import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import {
  CheckDoubleOrderResponse,
  CustomFormData,
  EnhancedDeclinedPaymentMessage,
  InterfacePost,
  OrderformLanguageOptions,
  OrderformOrderState,
  OrderformRedirectResponse,
  TypeCountriesList,
} from '@orderform/widgets';
import { OrderformFascade } from '../fascades/orderform.fascade';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { isPageBuilderDesignMode, isPreviewMode } from '../misc/pagebuilder';
import { cacheable } from '@ds24/utilities';

export const BLACKLIST_PARAMETERS = ['preview'];
export const EnterDataDelayAbandonmentCart = 300;
export const AbandonmentCartAllowedGETParams = ['abandoned_cart_delay_seconds'];
export const CHECK_DOUBLE_ORDER_GET_PARAMS_PASS_THROUGH = ['language'];

@Injectable({
  providedIn: 'root',
})
export class OrderformService {
  private _baseUrl: string;
  private _baseParams: string;

  static GET_COUNTRY_URL = '/buy/public_api/country_options';
  static GET_LANGUAGES_URL = '/buy/public_api/language_options/';

  getCountries$ = this._fascade.settings$.pipe(
    switchMap((settings) => {
      const config = settings.config;

      let req$ = this._httpClient
        .get<{ country_options: TypeCountriesList }>(
          OrderformService.GET_COUNTRY_URL + `?lang=${settings.global.language}`
        )
        .pipe(map((e) => e?.country_options || []));

      if (isPageBuilderDesignMode()) {
        req$ = of(<TypeCountriesList>[]);
      }

      return cacheable(() => req$, `get_countries`, {
        cacheFirstResult: true,
      });
    })
  );

  constructor(
    private _httpClient: HttpClient,
    private _fascade: OrderformFascade,
    @Inject(LOCALE_ID) private _locale: string
  ) {
    combineLatest([
      this._fascade.config$,
      this._fascade.globalSettings$,
    ]).subscribe(([config, globalSettings]) => {
      this._baseUrl = this._getBasePath(config.product_id, config.orderform_id);
      this._baseParams = this._getParams(globalSettings.language).toString();
    });

    this.initAbandonmentCart();
  }

  private _getBasePath(productId: number, orderformId: number) {
    let urlPath = '/' + window.location.pathname.replace(/^\/+/, '');
    if (isPageBuilderDesignMode()) {
      urlPath = '/product/' + productId + '/' + orderformId;
    }
    return urlPath;
  }

  private _getParams(language: string): HttpParams {
    let params = new HttpParams({
      fromString: window.location.search?.replace('?', ''),
    });

    /**
     * Pass all parameters (by blacklist)
     */
    params
      .keys()
      .filter((param) => BLACKLIST_PARAMETERS.indexOf(param) > -1)
      .forEach((param) => {
        params = params.delete(param);
      });

    if (isPreviewMode()) {
      params = params.append('preview', '1');
    }

    if (isPageBuilderDesignMode()) {
      params = params.append('pagebuilder', '1');
      // force request product json (e.g if product is not available)
      params = params.append('embedded_json_data_only', '1');
      params = params.append('language', language);
    }
    return params;
  }

  /**
   * Get Orderform JSON for Product
   * In PGB Mode Only
   * @param productId
   * @param orderformId
   * @param language
   */
  getOrderformJSON(productId: number, orderformId: number, language: string) {
    return this._httpClient.get<OrderformOrderState>(
      this._getBasePath(productId, orderformId) +
        '?json_data_only=1&' +
        this._getParams(language)
    );
  }

  /**
   * Get Orderform JSON for Product
   * In PGB Mode Only
   * @param productId
   * @param currentLanguage
   */
  getProductLanguages(
    productId: number,
    currentLanguage: string
  ): Observable<OrderformLanguageOptions[]> {
    return this._httpClient.get<OrderformLanguageOptions[]>(
      OrderformService.GET_LANGUAGES_URL +
        productId +
        `?language=${currentLanguage}`
    );
  }

  update$(value: InterfacePost): Observable<OrderformOrderState> {
    return this._httpClient.post<OrderformOrderState>(
      this._baseUrl + '?' + this._baseParams,
      {
        execute: {
          action: 'select',
        },
        ...value,
      }
    );
  }

  validate$(value: InterfacePost): Observable<OrderformOrderState> {
    return this._httpClient.post<OrderformOrderState>(
      this._baseUrl + '?' + this._baseParams,
      {
        execute: {
          action: 'check',
        },
        ...value,
      }
    );
  }

  send$(
    value: InterfacePost
  ): Observable<
    OrderformOrderState | OrderformRedirectResponse | { paymentUrl: string }
  > {
    return this._httpClient
      .post(
        this._baseUrl + '?' + this._baseParams,
        {
          execute: {
            action: 'submit',
          },
          ...value,
        },
        {
          responseType: 'text',
        }
      )
      .pipe(
        map((response) => {
          if (response.slice(0, 4) === 'http') {
            return { paymentUrl: response };
          }
          return JSON.parse(response);
        })
      );
  }

  sendAsFormdata(path: string, order: InterfacePost) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = path;

    const appendFormField = (key, value) => {
      const field = document.createElement('input');
      field.type = 'hidden';
      field.name = key;
      field.value = value;
      form.appendChild(field);
    };

    appendFormField('json', JSON.stringify(order));
    appendFormField('force_html_response', true.toString());

    document.body.appendChild(form);
    form.submit();
  }

  getLocaleIso2() {
    return this._locale.split('-').shift();
  }

  initAbandonmentCart() {
    /**
     * Abandonment Cart
     * Collect & validate relevant data
     */
    combineLatest([this._fascade.order$, this._fascade.settings$])
      .pipe(distinctUntilChanged(), debounceTime(EnterDataDelayAbandonmentCart))
      .subscribe(([orderState, settings]) => {
        const config = settings.config;
        const siteowner = config.siteowner_id;
        const ajax_key = config.ajax_key;
        const product_id = config.product_id;
        const orderform_id = config.orderform_id;
        const country = config.buyer_country;
        const address = orderState.order.shipping_address;
        const checkboxes = orderState.order.checkboxes;

        /**
         * Submit stopper
         */
        let submit = true;

        /**
         * Validation of Field
         * buyer_address_email
         */
        const checkRegexPostnameFields = ['buyer_address_email'];
        /**
         * Array of fields data (from BE)
         */
        const formValidationSettings =
          settings.contact_form.fields_shipping_address;

        /**
         * Validate fields for Abandonment Card POST
         */
        Object.keys(address)
          /**
           * Filter Fields by checkRegexPostnameFields
           */
          .filter((key) => checkRegexPostnameFields.includes(key))
          .forEach((postname) => {
            /**
             * Only check fields while submit is true
             */
            if (submit) {
              const value = address[postname];
              const validationForField = formValidationSettings.find(
                (entry) => entry.postname === postname
              );

              /**
               * Check by regex condition
               */
              if (validationForField && validationForField.regex) {
                submit = !!value.match(new RegExp(validationForField.regex));

                /**
                 * For testing perps
                 */
                // if(!submit) {
                //   console.log('invalid', validationForField.regex, validationForField.regex_message)
                // }
              }
            }
          });

        /**
         * Submit only if Validations was correct
         */
        if (submit) {
          this.sendAbandonmentCart(
            siteowner,
            ajax_key,
            product_id,
            orderform_id,
            country,
            address,
            checkboxes
          );
        }
      });
  }

  sendAbandonmentCart(
    siteowner: number,
    ajax_key: string,
    product_id: number,
    orderform_id: number,
    buyerCountry: string,
    addressData: CustomFormData,
    checkboxes: CustomFormData
  ) {
    /**
     * Collect data
     */
    const postSendData = {
      orderform_id: orderform_id,
      product_id: product_id,
      siteowner: siteowner,
      country: buyerCountry,
      ...addressData,
      ...checkboxes,
    };

    /**
     * Pass all parameters (by whitelist)
     */
    const params = this._getPassThroughGetParams(
      AbandonmentCartAllowedGETParams
    );
    return firstValueFrom(
      this._httpClient.post(
        `/extern/ajax/cart_abandonment/${ajax_key}?${params}`,
        postSendData
      )
    );
  }

  private _getPassThroughGetParams(paramsPassThrough: string[]): HttpParams {
    let params = new HttpParams({
      fromString: window.location.search?.replace('?', ''),
    });
    params
      .keys()
      .filter((param) => !paramsPassThrough.includes(param))
      .forEach((param) => {
        /**
         * Remove all params which not exists in the whiteList
         */
        params = params.delete(param);
      });
    return params;
  }

  checkDoubleOrder(
    ajax_key: string,
    email: string
  ): Observable<CheckDoubleOrderResponse> {
    let params = this._getPassThroughGetParams(
      CHECK_DOUBLE_ORDER_GET_PARAMS_PASS_THROUGH
    );
    params = params.append('email', email);
    return this._httpClient.post<CheckDoubleOrderResponse>(
      `/buy/public_api/check_double_order/${ajax_key}/?${params}`,
      null
    );
  }

  enhancedDeclinedPaymentMessage(
    main_product_id: number,
    payment_provider_id: number,
    operation: string,
    errorPayload: any
  ): Observable<EnhancedDeclinedPaymentMessage> {
    const apiUrl = '/v2/api/public/enhancedDeclinedPaymentMessage';
    const postData =
      {
        payment_provider_id,
        main_product_id,
        operation,
        errorPayload,
      };

    return this._httpClient.post<EnhancedDeclinedPaymentMessage>(apiUrl, postData);
  }
}
